import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["filter"];

  connect() {
    console.log("TEST1");
  }


  toggleSearchFilter(e) {
  
    console.log(e);
    console.log(e.relatedTarget);
    
    
    if (e.type == "focusout") {
      if (e.relatedTarget == null || e.relatedTarget.id.includes("search_option_") == false) {
      
          this.filterTarget.classList.add("invisible");
      
      }
    } else {
      this.filterTarget.classList.remove("invisible");
    }
    
  }

}