import { Controller } from "stimulus"
import Rails from 'rails-ujs'

export default class extends Controller {

  connect() {
    this.targetElements = ".article-badges, .step-percentages, .article-info"
  }

  get currentUserID() {
    return document.body.dataset.userId
  }

  simple() {
    document.querySelectorAll(this.targetElements).forEach((el) => {
      el.classList.add("invisible");
    });


    document.querySelectorAll(".article-card-view .article-info, .article-card-view .step-percentages").forEach((el) => {
      el.classList.add("hidden");
    })

    document.querySelectorAll(".article-card-view .article-card-inner").forEach((el) => {
      // el.classList.remove("h-24");
      // el.classList.add("h-20");

      el.style.minHeight = "450px";
      el.style.maxHeight = "450px";
    })

    this.saveMode("simple")
  }

  advanced() {
    document.querySelectorAll(this.targetElements).forEach((el) => {
      el.classList.remove("invisible");
    });

    document.querySelectorAll(".article-card-view .article-info, .article-card-view .step-percentages").forEach((el) => {
      el.classList.remove("hidden");
    });

    document.querySelectorAll(".article-card-view .article-card-inner").forEach((el) => {
      // el.classList.remove("h-20");
      // el.classList.add("h-24");

      el.style.minHeight = "500px";
      el.style.maxHeight = "500px";
    })


    this.saveMode("advanced")
  }

  saveMode(visualMode) {
    fetch(`/users/${this.currentUserID}/save-visual-mode`, {
      method: "post",
      credentials: "same-origin",
      headers: {
        'Accept': "application/json, text/plain, */*",
        "Content-Type": "application/json",
        'X-CSRF-Token': Rails.csrfToken(),
      },
      body: JSON.stringify({
        visualMode: visualMode
      }),
    }).then((response) => {
 
    });
  }
}
