import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["btn", "hnBtn", "modal", "inner", "extshare", "spinner", "shareSuccessMessage"];

  connect() {

    document.addEventListener("turbo:load", function() {
      window._goodshare.reNewAllInstance()
    });

   
  }

  // disconnect() {
  //   console.log("goodshare n")
  //   window._goodshare = null;
  // }

  copyURL() {
    var currentURL = document.getElementById("currentURL").value;
    navigator.clipboard.writeText(currentURL);
    
    document.querySelectorAll(".copied-url-tooltip").forEach((e) => {
      e.classList.remove("hidden");
  
      setTimeout(() => {
        e.classList.add("hidden");
      }, 1500);
    });
  }

  closeModal(e) {
    if (!this.modalTarget.contains(e.target) || e.target == this.modalTarget) this.modalTarget.classList.add("hidden");
  }

  openModal(e) {
    this.modalTarget.classList.remove("hidden")
    
  }

  shareOnHathor(e) {
    if (this.hasExtshareTarget) {
      this.extshareTarget.click();
      this.extshareTarget.remove();
    } else {
      this.spinnerTarget.classList.remove("hidden");
      this.hnBtnTarget.classList.add("bg-gray-200");
      
      setTimeout(() => {
        this.hnBtnTarget.setAttribute('disabled', true);
      }, 500);
  
      setTimeout(() => {
        this.spinnerTarget.classList.add("hidden")
        this.shareSuccessMessageTarget.classList.remove("hidden")
      }, 4500);
  
      setTimeout(() => {
        this.shareSuccessMessageTarget.classList.add("hidden")
      }, 10000);
    }
    
 
  }


}