import { Controller } from "stimulus";
import Pickr from "@simonwep/pickr";
import "../stylesheets/color_picker.css";

export default class extends Controller {
  static targets = ["picker", "input", "pickerLabel", "pickerContainer"]

  initialize() {
    this.initPicker();
    this.applyCustomColors();
  }

  initPicker() {
    if (!this.hasPickerTarget) return;
    
    this.picker = Pickr.create({
      el: this.pickerTarget,
      theme: 'nano',
      default: this.inputTarget.value,

      components: {
        preview: true,
        opacity: true,
        hue: true,
        

        interaction: {
          hex: true,
          rgba: false,
          hsla: false,
          hsva: false,
          cmyk: false,
          input: true,
          clear: false,
          save: true,
        },
      },

        i18n: {
          'btn:save': 'Conferma',
        }

    });
    


    this.picker.on('change', (color, _isntance) => {
      var pickerType = this.inputTarget.dataset.pickerType
      this.inputTarget.value = color.toHEXA().toString();

      document.querySelector("#article_card_content").classList.remove("dark-theme")

      if ( pickerType == "background") {
        document.querySelector("#article_card_content").style.backgroundColor = color.toHEXA().toString();
      } else if ( pickerType == "text" ) {
        document.querySelectorAll(".article-content-formatted, .article-content-formatted p, .article-content-formatted span, #article_card_content h1").forEach(el => el.style.color = color.toHEXA().toString());
      }


    })

    this.picker.on('save', (color, _instance) => {
      this.inputTarget.value = color.toHEXA().toString();

      this.picker.hide();
    });
  }

  openPicker() {
    if (document.querySelector(".pcr-app").classList.contains("visible")) return;
    this.pickerContainerTarget.querySelector(".pcr-button").click()
  }

  applyCustomColors() {
    var pickerType = this.inputTarget.dataset.pickerType
    var customArticleStyles = JSON.parse(document.querySelector("body").dataset.customArticleThemeStyles)
    var backgroundColor = customArticleStyles["backgroundColor"] == undefined ? "" : customArticleStyles["backgroundColor"]
    var textColor = customArticleStyles["textColor"] == undefined ? "" : customArticleStyles["textColor"]
    if (this.hasPickerContainerTarget) {
      this.pickerContainerTarget.querySelector(".pcr-button").style = `--pcr-color:${pickerType == "background" ? backgroundColor : textColor}`
    }
  }
}